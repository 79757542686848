import dp from './Shashank Nath DP.jpg';
import dp_dark from './Shashank Nath DP-Dark.jpg';
import './App.css';
import { FaBaby , FaGraduationCap, FaBrain, FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaBasketballBall} from 'react-icons/fa';
import { GiSchoolBag, GiCubes, GiEngagementRing, GiBriefcase } from 'react-icons/gi';
import { MdLightMode } from 'react-icons/md';
import {useState} from 'react';

const font_size = 'text-3xl sm:text-5xl'
const life_stages=[{
                    "icon":<FaBaby className={font_size} />,
                    "title":"Born",
                    "values":['Assam'],
                    "css":"col-start-1 md:border-t-2 md:border-l-0 half-border dark:half-border-dark",
                    "css2":"md:inline-flex"
                    },
                    {
                      "icon":<GiSchoolBag  className={font_size}/>,
                      "title":"Schooling",
                      "values":['KV N0 1-2 - Dehradun', 'KV MEG - Bangalore'],
                      "css":"row-start-2 md:row-start-1 md:col-start-2 xl:row-start-1 lg:row-start-1 lg:col-start-2 md:border-l-0 md:border-t-2 md:border-r-2 lg:border-r-0"
                    },
                    {
                      "icon":<FaGraduationCap  className={font_size}/>,
                      "title":"College",
                      "values":['HIT - Dehradun', 'NHCE - Bangalore'],
                      "css":"row-start-3 md:row-start-2 md:col-start-2 lg:row-start-1 lg:col-start-3 md:border-l-0 md:border-t-2 lg:border-r-2 xl:border-r-0"
                    },
                    {
                      "icon":<GiBriefcase  className={font_size}/>,
                      "title":"Work",
                      "values":['IQVIA - Bangalore', 'Genpact - Hyderabad'],
                      "css":"row-start-4  md:row-start-2 md:col-start-1 lg:row-start-2 lg:col-start-3 xl:row-start-1 xl:col-start-4 md:border-l-2  md:border-t-2 xl:border-r-2 lg:border-l-0"
                    },
                    {
                      "icon":<FaBrain  className={font_size}/>,
                      "title":"Skills",
                      "values":['Python', 'SAS', 'SQL', 'HTML | JavaScript'],
                      "css":"row-start-5  md:row-start-3 md:col-start-1 lg:row-start-2 lg:col-start-2 xl:col-start-2 xl:col-start-4 md:border-t-2 md:border-l-0"
                    },
                    {
                      "icon":<GiCubes  className={font_size}/>,
                      "title":"Projects",
                      "values":['Teamui.in', 'aradhyamansions.com', 'SAS Help [Play Store]'],
                      "css":"row-start-6  md:row-start-3 md:col-start-2 lg:row-start-2 lg:col-start-1  xl:row-start-2 xl:col-start-3 md:border-t-2 md:border-l-0 md:border-r-2 lg:border-r-0 lg:border-l-2 xl:border-l-0"
                    }
                    ,
                    {
                      "icon":<FaBasketballBall  className={font_size}/>,
                      "title":"Hobbies",
                      "values":['Basket Ball', 'Web Design', 'Playing Guitar'],
                      "css":"row-start-7  md:row-start-4 md:col-start-2 lg:row-start-3 lg:col-start-1 xl:row-start-2 xl:col-start-2 md:border-l-0 md:border-t-2 lg:border-r-0"
                    }
                    ,
                    {
                      "icon":<GiEngagementRing  className={font_size}/>,
                      "title":"Status",
                      "values":['Married, Yup finally found my better half 💘.'],
                      "css":"row-start-8  md:row-start-4 md:col-start-1 lg:row-start-3 lg:col-start-2 xl:row-start-2 xl:col-start-1 md:border-l-0 md:border-t-2",
                      "css2":"md:inline-flex xl:inline-flex"
                    }

                  ]

function LifeStage(attr){
  return (<div className={attr.item.css+' md:border-b-0 border-r-0 border-solid md:border-dashed border-gray-200 md:border-gray-300 dark:border-gray-700 py-7 z-0 relative '+ (attr.index ===7?'border-b-0':'border-b-2')} key={attr.index}>
            <div className={"flex flex-col items-center transform-gpu md:-translate-y-36"}>
                    <h3 className="font-normal text-xl mb-4 font-mono">{attr.item.title}</h3>
                    <div className="flex items-center justify-center rounded-full w-20 h-20 sm:w-36 sm:h-36 border-4 border-white overflow-hidden shadow-xl logo-bg dark:logo-bg-dark"
                    >
                      {attr.item.icon}
                    </div>
                    <div className="min-w-full flex mt-2">
                      <div className="md:w-2/4"></div>
                      <ul className="md:w-2/4 md:list-disc text-gray-600 dark:text-gray-300 w-full text-center md:text-left">
                        {
                          attr.item.values.map((value, index)=>
                            <li className="mt-2" key={index}>{value}</li>)
                        }
                      </ul>
                    </div>
                  </div>
                </div>)
}


function App() {
  const [dp_image, set_dp] = useState(dp_dark)
  const [dark, set_dark] = useState(true)
  function change_dark_mode(){
    set_dark(dark_=>{
      // console.log(dark_)
      if(!dark_){
        document.body.classList.add('dark');
        set_dp(dp_dark)
      }
      else{
        document.body.classList.remove('dark');
        set_dp(dp)
      }
      return !dark_;
    });

    return dark
  }
  return (
    <div className="w-4/5 xl:w-4/6 mx-auto ">
        <section className="flex flex-col items-center py-7 md:py-10 xl:py-20 2xl:py-40">
            <img src={dp_image} alt="Shashank Nath" className="h-48 w-48 sm:w-60 sm:h-60 md:w-80 md:h-80 border-4 border-solid border-white rounded-full shadow-2xl"/>
            <button className="-mt-6 dark:bg-gray-800 bg-gray-200 rounded-full p-2 shadow-2xl border-4 border-solid border-gray-50 relative" onClick={change_dark_mode}>
              <span className="animate-ping absolute top-0 left-0 h-full w-full rounded-full bg-gray-50 opacity-75"></span>
              <MdLightMode size={'1.6em'} className="dark:text-gray-200 text-gray-800" /></button>
            <div className="flex flex-col items-center my-10">
              <h1 className="text-3xl sm:text-5xl font-bold text-center text-gray-700 dark:text-gray-200">SHASHANK NATH</h1>
              <h3 className="text-lg sm:text-xl mt-2 block dark:text-gray-200">Data Scientist</h3>
            </div>
            <ul className="list-none flex">
              <li><a href="https://www.facebook.com/shashank.me.shanky" target="_blank" rel="noreferrer"><FaFacebookSquare size='2em'  className='text-gray-600 hover:text-gray-400'/></a></li>
              <li><a href="https://www.instagram.com/shanky.arka" target="_blank" rel="noreferrer"><FaInstagramSquare  size='2em' className='text-gray-600 mx-2 hover:text-gray-400' /></a></li>
              <li><a href="https://www.linkedin.com/in/shashank-nath" target="_blank" rel="noreferrer"><FaLinkedin  size='2em'className='text-gray-600 hover:text-gray-400'/></a></li>
            </ul>
        </section>
        <section className="grid  grid-cols-1 grid-rows-8 md:grid-rows-4 md:grid-cols-2 lg:grid-rows-3 lg:grid-cols-3 xl:grid-rows-2 xl:grid-cols-4 mt-0 md:mt-36">
          {
            life_stages.map((stage, index)=><LifeStage item={stage} index={index}/>)
          }
        </section>
        <section >
          <h3 className="text-center text-gray-700 py-5">Powered By Me</h3>
        </section>
    </div>
  );
}

export default App;
